import React, { useState } from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const NavItem = styled(AnchorLink)`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${(props) => props.theme.colors.blue[600]};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${(props) => props.theme.colors.white};
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const NavItemFun = styled.a`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${(props) => props.theme.colors.blue[600]};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const DropDownContainer = styled('div')`
  position: relative;
  display: inline-block;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${(props) => props.theme.colors.blue[600]};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    ::after {
      width: 100%;
    }
  }

  &:hover ${DropDownListContainer} {
    display: block;
  }
`

const DropDownHeader = styled('div')`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;
`

const DropDownListContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.primary[900]};
  position: absolute;
  z-index: 1;
`

const DropDownList = styled('ul')`
  text-decoration: none;
  margin: 0 1vw;
  &:first-child {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
`

const ListItemFun = styled.a`
  list-style: none;
  margin-bottom: 0.8em;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${(props) => props.theme.colors.blue[600]};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${(props) => props.theme.colors.white};
    ::after {
      width: 100%;
    }
  }
`

const NavbarLinks = ({
  handlOpenContact,
  jobOffer,
  handlOpenModal,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value) => () => {
    console.log(value)
    setSelectedOption(value.title)
    setIsOpen(false)
    value.function === true ? handlOpenModal(true) : console.log('hi')
  }

  return (
    <>
      <NavItem to="/es/#about">Quienes somos</NavItem>
      <NavItem to="/es/#services">Servicios</NavItem>
      <NavItem to="/es/europe">AiBrok Europe</NavItem>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>{jobOffer}</DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItemFun
                  onClick={onOptionClicked(option)}
                  href={option.path}
                >
                  {option.title}
                </ListItemFun>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      <NavItemFun onClick={handlOpenContact}>Contacto</NavItemFun>
    </>
  )
}

export default NavbarLinks
