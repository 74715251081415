import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, OpenModalButton } from '@components/Modal'


import NavbarLinks from './NavbarLinks'

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: 2px solid #33333320;
  z-index: 2;
  align-self: center;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: ${(props) => props.theme.colors.primary[900]};
    transition: all 0.3s ease-in;
    top: 100px;
    left: ${(props) => (props.open ? '-100%' : '0')};
  }
`

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
      props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`
const Navbar = ({ handlOpenModal, handlOpenContact, jobOffer, options }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      <Navbox open={!navbarOpen} onClick={() => setNavbarOpen(false)}>
        <NavbarLinks
          handlOpenContact={handlOpenContact}
          jobOffer={jobOffer}
          handlOpenModal={handlOpenModal}
          options={options}
        />
      </Navbox>
    </Navigation>
  )
}

export default Navbar
