import React, { useState } from 'react'
import { Link } from '@components/Link'
import { Navbar } from '@components/Navbar'
import { Box, Flex } from '@components/Grid'
import { Modal } from '@components/Modal'
import Logo from '../../images/Logo/aibrok_blanco3.png'

const Header = () => {
  const [isOpen, isClose] = useState(false)
  const [contact, setContact] = useState(false)
  const options = [
    { title: 'Ofertas laborales', id: 1, path: `/es/joinUs` },
    { title: 'Enviar CV', id: 2, function: true },
  ]
  const handlOpenContact = () => {
    console.log('close modal')
    setContact(true)
    isClose(true)
  }
  const handlOpenModal = (open) => {
    console.log('close modal')
    setContact(false)
    isClose(open)
  }
  return (
    <Box as="header" color="white" bg="primary.900" px={[3, 6]} py={4}>
      <Box m="0 auto">
        <Flex justifyContent="space-between" alignItems="center">
          <Link to="/" css={{ textDecoration: 'none' }}>
            <img src={Logo} alt="" height="80px" />
          </Link>
          <Navbar
            handlOpenModal={handlOpenModal}
            handlOpenContact={handlOpenContact}
            jobOffer="Únete a Nosotros"
            options={options}
          />
        </Flex>
      </Box>
      <Modal
        isOpen={isOpen}
        contact={contact}
        handleClose={() => handlOpenModal(false)}
      />
    </Box>
  )
}

export default Header
