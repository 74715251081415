import React from 'react'
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'

import Header from '@components/Header'
import Footer from '@components/Footer'
import { Box } from '@components/Grid'
import AOS from 'aos'
import 'aos/dist/aos.css'
import GlobalStyles from '@style/GlobalStyles'
import { ThemeProvider, theme } from '@style'

const Layout = ({ children }) => {

  React.useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <>
        <SnackbarProvider maxSnack={1}>
          <GlobalStyles />
          <Header />
          <Box as="main" m="0 auto">
            {children}
            <Box mt={3}>
              <Footer />
            </Box>
          </Box>
        </SnackbarProvider>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
