import React, { useState } from 'react'
import styled from '@style'
import { InlineIcon } from '@iconify/react'
import envelopeAlt from '@iconify/icons-uil/envelope-alt'

import { Box, Flex } from '@components/Grid'
import { Navlink, OutboundLink, LinkFun } from '@components/Link'
import { Text } from '@components/Text'
import LogoDesktop from '@images/Logo/aibrok1.png'
import LogoMobile from '@images/Logo/logosimbolo.png'
import { Modal } from '@components/Modal'

const StyledLink = styled(OutboundLink)`
  padding-left: 8px;
  color: inherit;
  text-decoration: none;
`

const TermsLink = styled(Navlink)`
  padding-bottom: 0px;
  margin-bottom: 0px;
`
const DropDownContainer = styled('div')`
  position: relative;
  display: inline-block;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${(props) => props.theme.colors.blue[600]};
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    cursor: pointer;
    ::after {
      width: 100%;
    }
  }

  &:hover ${DropDownListContainer} {
    display: block;
  }
`

const DropDownHeader = styled('div')`
  text-decoration: none;
  color: $ ${(props) => props.theme.colors.primary[900]};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;
`

const DropDownListContainer = styled('div')`
  background-color: #fff;
  position: absolute;
  z-index: 1;
`

const DropDownList = styled('ul')`
  text-decoration: none;
  margin: 0 1vw;
  &:first-child {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
`

const ListItemFun = styled.a`
  list-style: none;
  margin-bottom: 0.8em;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[900]};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 14px;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: #fff;
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: ${(props) => props.theme.colors.primary[900]};
    ::after {
      width: 100%;
    }
  }
`
const Footer = () => {
  const [isOpen, isCLose] = useState(false)
  const [contact, setContact] = useState(false)
  const options = [
    { title: 'OFERTAS LABORALES', id: 1, path: `/es/joinUs` },
    { title: 'ENVIAR CV', id: 2, function: true },
  ]
  const handlOpenModal = (open) => {
    console.log('close modal')
    setContact(false)
    isCLose(open)
  }
  const handlOpenContact = () => {
    console.log('close modal')
    setContact(true)
    isCLose(true)
  }
  const [isOpenDrop, setIsOpenDrop] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const jobOffer = 'ÚNETE A NOSOTROS'
  const toggling = () => setIsOpenDrop(!isOpenDrop)

  const onOptionClicked = (value) => () => {
    console.log(value)
    setSelectedOption(value.title)
    setIsOpenDrop(false)
    value.function === true ? handlOpenModal(true) : console.log('hi')
  }
  return (
    <Flex
      as="footer"
      bg="white"
      mb={3}
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      alignItems="center"
      color="primary.900"
      borderTop="1px solid black"
      id="contact"
    >
      <Flex
        alignItems="center"
        borderBottom={['solid black 1px', 'none']}
        justifyContent="space-between"
        width={['100%', 'initial']}
        p={3}
      >
        <Box>
          <Box display={['none', 'block']}>
            <img src={LogoDesktop} alt="" height="80px" />
          </Box>
          <Box display={['block', 'none']} p={2}>
            <img src={LogoMobile} alt="" height="46px" />
          </Box>
        </Box>
        <Flex
          flexDirection="column"
          ml={3}
          maxWidth="280px"
          borderLeft="solid black 1px"
          pl={3}
          flexGrow="1"
        >
          <Box py={2}>
            <InlineIcon icon={envelopeAlt} />
            <StyledLink to="mailto:info@aibrok.com.mx" pl={2}>
              info@aibrok.com.mx
            </StyledLink>
          </Box>
          {/* <Box px={2}>
            <StyledLink href="tel:+34955441990">+34 955 44 19 90 </StyledLink>
          </Box> */}
          <Box px={2}>
            <Text as="small">© Aibrok 2023.</Text>
          </Box>
          <Box px={2}>
            <Text as="small">Todos los derechos reservados</Text>
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection="column" mr={[0, 8]}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          mt={[4, 0]}
          pt={3}
        >
          <Box px={2} mb={[5, 2]}>
            <Navlink color="primary.900" to="/es/#about">
              Quienes somos
            </Navlink>
          </Box>
          <Box px={2} mb={[5, 2]}>
            <Navlink color="primary.900" to="/es/#services">
              Servicios
            </Navlink>
          </Box>

          <Box px={2} mb={[5, 2]}>
            <DropDownContainer>
              <DropDownHeader onClick={toggling}>{jobOffer}</DropDownHeader>
              {isOpenDrop && (
                <DropDownListContainer>
                  <DropDownList>
                    {options.map((option) => (
                      <ListItemFun
                        onClick={onOptionClicked(option)}
                        href={option.path}
                      >
                        {option.title}
                      </ListItemFun>
                    ))}
                  </DropDownList>
                </DropDownListContainer>
              )}
            </DropDownContainer>
          </Box>
          <Box px={2} mb={[5, 2]}>
            <LinkFun color="primary.900" onClick={handlOpenContact}>
              Contacto
            </LinkFun>
          </Box>
        </Flex>
        <Modal
          isOpen={isOpen}
          contact={contact}
          handleClose={() => handlOpenModal(false)}
        />
        <Flex flexDirection={['column', 'row']} alignItems="center" p={3}>
          <Box px={2} mb={[5, 2]}>
            <TermsLink color="primary.900" to="/es/cookiesPolicies">
              <Text as="small">Politicas de Cookies</Text>
            </TermsLink>
          </Box>
          <Box px={2} mb={[5, 2]}>
            <TermsLink color="primary.900" to="/es/termsConditions">
              <Text as="small">Términos y condiciones</Text>
            </TermsLink>
          </Box>
          <Box px={2} mb={[5, 2]}>
            <TermsLink color="primary.900" to="/es/privacyPolicies">
              <Text as="small">Aviso de Privacidad</Text>
            </TermsLink>
          </Box>
          <Box px={2}>
            {/* <Box px={2}>
              <TermsLink color="primary.900" to="/es">
                <Text as="small">Español</Text>
              </TermsLink>{' '}
            </Box>
            <Box px={2}>
              <TermsLink color="primary.900" to="/">
                <Text as="small">English</Text>
              </TermsLink>
            </Box> */}
            {/* <Box px={2}>
              <TermsLink color="primary.900" to="/pt">
                <Text as="small">Português</Text>
              </TermsLink>
            </Box>
            <Box px={2}>
              <TermsLink color="primary.900" to="/it">
                <Text as="small">Italiano</Text>
              </TermsLink>
            </Box> */}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer
